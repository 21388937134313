.component-header {
  height: 10vh;
  display: flex;
  align-items: center;

  &__logo {
    position: absolute;
    top: 0;
    right: 5%;
    width: 10% !important;
    height: 150px;
    background-color: transparent;
    padding: 0 !important;
    text-align: center !important;
    border-radius: 0 0 10px 10px;
    cursor: pointer;
  }
}
