.background-home-component {
    background-color: #FFF;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    overflow: hidden;
    background-image: url('../../../../assets/png/bg-unimarc.png');
    background-repeat: no-repeat;

    @media(min-width: 1300px) {
        background-image: url('../../../../assets/png/bg-unimarc-1080.png');
        background-size: cover;
        background-size: 103%;
        background-position: -10px -10px;
        background-repeat: no-repeat;
    }
    
}