.component-quantity {
  &__actions {
    width: 130px;
    height: 45px;
    background-color: #f8faff;
    color: #9aa0b1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    &:first-child {
      border-radius: 9px 0 0 9px;
      border-left: 1px solid #e3e3e3;
      border-top: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
    }

    &:last-child {
      border-radius: 0 9px 9px 0;
      border-right: 1px solid #e3e3e3;
      border-top: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
    }
  }

  &__input {
    width: 100%;
    height: 45px;
    padding: 0;
    border: none;
    text-align: center;
    background-color: #fff;
    color: rgb(116, 116, 116);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    font-size: 1.2em;
    font-weight: bold;
  }
}
