.content-section {
  height: 90vh;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.size-06 {
  font-size: 0.6em;
}

.size-07 {
  font-size: 0.7em;
}

.size-08 {
  font-size: 0.8em;
}

.size-09 {
  font-size: 0.9em;
}

.size-11 {
  font-size: 1.1em;
}

.size-12 {
  font-size: 1.2em;
}

.size-13 {
  font-size: 1.3em;
}

.size-14 {
  font-size: 1.4em;
}

.size-15 {
  font-size: 1.5em;
}
