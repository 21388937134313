.multimedia-component-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);

    &__header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 70px;
    }

    &__footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 97px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

iframe {
    pointer-events: none !important;
}